import type { PageClientSide } from '@readme/backend/models/page/types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import usePagination from '@core/hooks/usePagination';

import DateLine from '@ui/DateLine';
import PageThumbs from '@ui/PageThumbs';
import PaginationControls from '@ui/PaginationControls';
import WhatsNext from '@ui/WhatsNext';

interface DocFooterProps {
  doc: PageClientSide;
  project: ProjectClientSide;
  version: string;
}

function DocFooter({ doc, project, version }: DocFooterProps) {
  const { prev, next } = usePagination<PageClientSide>(doc);
  const location = useLocation();
  const pathname = location?.pathname;

  const metricsFetch = useMetricsAPIFetcher({ version });

  const pageType = doc?.isReference ? 'reference' : 'docs';

  const sendToMetrics = useCallback(
    body => {
      return metricsFetch({
        path: 'create/thumb',
        method: 'POST',
        body,
      });
    },
    [metricsFetch],
  );

  const showWhatsNext = Boolean(doc?.next?.pages?.length || doc?.next?.description);

  return (
    <React.Fragment key={pathname}>
      <div className="UpdatedAt">
        <DateLine icon="icon icon-watch" prefix="Updated" tag="p" time={doc?.updatedAt} />
      </div>
      <hr className="NextStepsDivider" />
      {showWhatsNext ? (
        <WhatsNext
          description={doc.next.description}
          label={project.appearance.nextStepsLabel}
          pages={doc.next.pages}
        />
      ) : (
        <PaginationControls next={next} pageType={pageType} prev={prev} />
      )}
      {!!project?.metrics?.thumbsEnabled && (
        <PageThumbs pathname={pathname} projectSubdomain={project.subdomain} sendToMetrics={sendToMetrics} />
      )}
    </React.Fragment>
  );
}

export default DocFooter;
